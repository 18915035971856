<template>
  <ProfileButton />
  <Loading v-if="isLoading" />
  <button class="btn btn-link back-button" @click="redirectToAccounts">
    <img src="@/assets/images/arrow-left-silver.png" alt="Voltar" />
  </button>
  <div class="d-flex flex-column align-items-center justify-content-top">
    <img :src="account.picture" alt="Foto de perfil" class="picture-profile circle-green config-profile-picture" />
    <span class="fw-bold fs-15 mt-2">
      @{{ account.name }}
    </span>
    <span class="fw-bold fs-20 mt-4">Configurações</span>
    <div class="d-flex flex-column mt-3">
      <span class="fc-label fw-bold fs-12">Visualização</span>
      <div class="d-flex justify-content-between">
        <div>
          <input type="radio" id="stories" name="radio-group-view" value="stories" v-model="account.view">
          <label for="stories" class="fs-12 fc-label">Stories</label>
        </div>
        <div>
          <input type="radio" id="reels" name="radio-group-view" value="reels" v-model="account.view">
          <label for="reels" class="fs-12 fc-label">Reels</label>
        </div>
        <div>
          <input type="radio" id="storiesreels" name="radio-group-view" value="stories_and_reels" v-model="account.view">
          <label for="storiesreels" class="fs-12 fc-label">Stories/Reels</label>
        </div>
      </div>

      <hr />

      <span class="fc-label fw-bold fs-12">Tempo de exibição (fotos)</span>
      <div class="d-flex justify-content-between">
        <div>
          <input type="radio" id="5seconds" name="radio-group-seconds" :value="5" v-model="account.duration">
          <label for="5seconds" class="fs-12 fc-label">5 segundos</label>
        </div>
        <div class="mx-4">
          <input type="radio" id="10seconds" name="radio-group-seconds" :value="10" v-model="account.duration">
          <label for="10seconds" class="fs-12 fc-label">10 segundos</label>
        </div>
        <div>
          <input type="radio" id="15seconds" name="radio-group-seconds" :value="15" v-model="account.duration">
          <label for="15seconds" class="fs-12 fc-label">15 segundos</label>
        </div>
      </div>

      <hr />

      <div>
        <input type="checkbox" id="random" v-model="account.random" />
        <label for="random" class="fc-label fs-12">Modo aleatório</label>
      </div>

      <hr />

      <span class="fc-label fw-bold fs-12">Tela</span>
      <div class="d-flex">
        <div>
          <input type="radio" id="horizontal" name="radio-group-mode" value="horizontal" v-model="account.mode" @change="showQuantityOptions">
          <label for="horizontal" class="fs-12 fc-label">Horizontal</label>
        </div>
        <div class="mx-4">
          <input type="radio" id="vertical" name="radio-group-mode value=" value="vertical" v-model="account.mode" @change="showQuantityOptions">
          <label for="vertical" class="fs-12 fc-label">Vertical</label>
        </div>
      </div>

      <hr />

      <span class="fc-label fw-bold fs-12">Quantidade de exibições por tela</span>
      <div class="d-flex">
        <div>
          <input type="radio" id="onlyone" name="radio-group-quantity" :value="1" v-model="account.quantity_show">
          <label for="onlyone" class="fs-12 fc-label">1</label>
        </div>
        <div class="mx-4" v-if="account.mode === 'horizontal'">
          <input type="radio" id="three" name="radio-group-quantity" :value="3" v-model="account.quantity_show">
          <label for="three" class="fs-12 fc-label">3</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api'
import ProfileButton from '@/components/ProfileButton.vue'
import Loading from '@/components/Loading.vue'

export default {
  name: 'AccountConfig',
  data() {
    return {
      account: {},
      isLoading: true,
    }
  },
  components: {
    ProfileButton,
    Loading
  },
  created() {
    this.fetchAccount(this.$route.params.id)
  },
  methods: {
    fetchAccount(id) {
      api.accounts.getById(id)
        .then(response => {
          this.account = response.data
        })
        .catch(error => {
          console.error(error)
        }).finally(() => {
          this.isLoading = false
        })
    },
    redirectToAccounts() {
      this.$router.push('/accounts')
    },
    updateAccount(account) {
      api.accounts.update(account)
        .catch(error => {
          console.error(error)
        })
    },
    showQuantityOptions() {
      if (this.account.mode === 'vertical') {
        this.account.quantity_show = 1
      }
    }
  },
  watch: {
    account: {
      handler(account) {
        this.updateAccount(account)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.back-button {
  position: absolute;
  top: 30px;
  left: 30px;
}

.picture-profile {
  width: 75px;
  height: 75px;
  border-radius: 50%;
}

[type="radio"]:checked,
[type="radio"]:not(:checked),
[type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label,
[type="checkbox"]:checked + label,
[type="checkbox"]:not(:checked) + label {
    position: relative;
    padding-left: 18px;
    cursor: pointer;
    line-height: 14px;
    display: inline-block;
    color: #9E9B9B;
    font-weight: bold;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before,
[type="checkbox"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 12px;
    height: 12px;
    border: 1px solid #9E9B9B;
    border-radius: 100%;
    background: #FFF;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after,
[type="checkbox"]:not(:checked) + label:after {
    content: '';
    width: 8px;
    height: 8px;
    background: #9E9B9B;
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after,
[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="radio"]:checked + label:after,
[type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

</style>
