<template>
  <ProfileButton />
  <Loading v-if="loading" />
  <AccountCard
    v-else-if="accounts"
    :accounts="accounts"
    :current-account="current_account"
    @delete-account="deleteAccount"
  />
  <AccountCard v-else />
</template>

<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import AccountCard from './components/AccountCard.vue'
import ProfileButton from '@/components/ProfileButton.vue'
import Loading from '@/components/Loading.vue'

export default {
  name: 'AccountsView',
  components: {
    AccountCard,
    ProfileButton,
    Loading
  },
  data() {
    return {
      accounts: [],
      current_account: 0,
      loading: true
    }
  },
  created() {
    if (!this.currentUser) {
      this.$router.push('/sign-in')
    }
    if (this.$route.query.code) {
      this.addAccounts(
        this.$route.query.code,
        this.$route.query.state
      )
    } else {
      this.fetchAccounts()
    }
  },
  computed: {
    ...mapState('auth', ['currentUser'])
  },
  methods: {
    fetchAccounts() {
      this.loading = true
      api.accounts.get()
        .then(response => {

          this.accounts = response.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    addAccounts(code, state) {
      api.accounts.callback({ code, state })
        .then(() => {
          this.fetchAccounts()
        })
        .catch(() => {
          this.loading = false
        })
    },
    deleteAccount(id) {
      this.loading = true
      api.accounts.delete(id)
        .then(() => {
          this.fetchAccounts()
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>